import React from 'react'
import EngineeringTemplate from 'components/templates/EngineeringTemplate/EngineeringTemplate'

export default function EngineeringTestingPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Collaudi'

  const description =
    'Il collaudo delle opere ha lo scopo di:<br><br> • Verificare e certificare che l’opera o il lavoro siano stati eseguiti a regola d’arte e secondo le regole tecniche prestabilite, in conformità del contratto,<br> delle varianti e conseguenti atti di sottomissione debitamente approvati;<br> • Verificare che i dati risultanti dalla contabilità e dai documenti giustificativi<br> corrispondono tra loro e con le risultanze di fatto; Comprende altresì tutte le verifiche tecniche previste da leggi di settore.<br> • Esaminare le riserve dell’appaltatore sulle quali non sia intervenuta una risoluzione definitiva in via amministrativa, qualora iscritte nel registro di contabilità e nel conto finale nei modi e termini prescritti.<br><br> <b>ING GROUP S.r.l. dispone di un’equipe di ingegneri disponibile per interventi su tutto il territorio nazionale.</b>'

  return (
    <>
      <EngineeringTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
